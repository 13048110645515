import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Banners from './banners'
import { formatDate } from '../helpers/dateHelper'
import { myLogueUrl } from '../helpers/urlHelper'

const Sidebar = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulPost(sort: {fields: createdAt, order: DESC}) {
          edges {
            node {
              id
              slug
              title
              category {
                ... on ContentfulCategory1 {
                  name
                  slug
                }
                ... on ContentfulCategory2 {
                  name
                  slug
                  category1 {
                    slug
                    name
                  }
                }
                ... on ContentfulCategory3 {
                  name
                  slug
                  category2 {
                    slug
                    name
                    category1 {
                      slug
                      name
                    }
                  }
                }
              }
              postTags {
                tag
                slug
              }
              createdAt
              image {
                fluid (maxWidth: 200) {
                  src
                  srcWebp
                }
              }
            }
          }
        }
        allPageViews(sort: {fields: totalCount, order: DESC}) {
          edges {
            node {
              path
            }
          }
        }
      }
    `}
    render={data => {
      const popularCards = []
      const allPosts = data.allContentfulPost.edges
      const posts = []
      data.allPageViews.edges.forEach(edge => {
        const post = allPosts.find(e => myLogueUrl(e.node).slice(myLogueUrl(e.node).lastIndexOf('/')) === edge.node.path.slice(edge.node.path.lastIndexOf('/')))
        if (post) {
          posts.push(post)
        }
      })
      for (let i in posts) {
        const path = myLogueUrl(posts[i].node)
        popularCards.push(
          <li key={i} className="sidebar__item">
            <Link className="sidebar__link d-flex justify-content-between align-items-start"
              to={path}>
              <picture>
                <source src={posts[i].node.image.fluid.srcWebp} type="image/webp" />
                <img
                  loading="lazy"
                  src={posts[i].node.image.fluid.src}
                  title={posts[i].node.image.title}
                  alt={posts[i].node.image.description}
                  className="sidebar__thumb"
                  style={{ objectFit: "cover" }}
                />
              </picture>
              <div className="sidebar__wrap">
                <div className="post__date">{formatDate(new Date(posts[i].node.createdAt))}</div>
                <h4>{posts[i].node.title}</h4>
              </div>
            </Link>
          </li>
        )
      }
      return (
        <div className="sidebar">
          {/* NOTE: 新規流入停止中
              https://www.pivotaltracker.com/story/show/181292782 */}
          {/* <Banners /> */}
          <div className="sidebar__contents">
            <div className="sidebar__heading d-flex justify-content-between align-items-center">
              <h4>POPULAR</h4>
              <p>人気記事</p>
            </div>
            <div className="sidebar__post">
              <ul className="sidebar__list">
                {popularCards.slice(0, 5)}
              </ul>
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default Sidebar
